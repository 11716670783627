<template>
  <b-sidebar
    id="sidebar-invoice-add-new-supplier"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :visible="isAddNewUserSidebarActive"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Ajouter fournisseur
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >
        <!-- company -->
        <b-form-group
          label="Nom"
          label-for="company-name"
        >
          <b-form-input
            id="company-name"
            v-model="supplier.company_name"
            trim
            placeholder="nom"
          />
        </b-form-group>
        <!-- first name -->

        <!-- Email -->
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="supplier.email"
            trim
            placeholder="example@domain.com"
            type="email"
          />
        </b-form-group>

        <!-- Address -->
        <b-form-group
          label="Address"
          label-for="address"
        >
          <b-form-textarea
            id="address"
            v-model="supplier.address"
            placeholder="Avenue ..."
            trim
          />
        </b-form-group>

        <!-- phone -->
        <b-form-group
          label="phone"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="supplier.phone"
            type="number"
            placeholder="tel"
            trim
          />
        </b-form-group>
        <b-form-group
          label="cin"
          label-for="cin"
        >
          <b-form-input
            id="cin"
            v-model="supplier.cin"
            type="number"
            placeholder="cin"
            trim
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="save"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// import axiosIns from '@/libs/axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['add-new-supplier'],
  setup(props, { emit }) {
    const supplier = ref({
      company_name: '',
      email: '',
      phone: '',
      address: '',
      tax_identification_number: '',
      cin: '',
    })

    const save = async () => {
      emit('add-new-supplier', supplier.value)
    }

    return {
      supplier,
      save,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
